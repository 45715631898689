import config from '@/config';

export const TABLE_FILTERS = {
  DATE: 'DATE',
  DATE_RANGE: 'DATE_RANGE',
  TEXT: 'TEXT',
  NUMBER: 'NUMBER'
};

export const PRETTY_DATE_FORMAT_DP = 'MM-DD-YYYY';
export const DB_DATETIME_FORMAT_HH_MM_SS = "yyyy-MM-dd'T'HH:mm:ssZZ";
export const DB_COMPLETE_DATE_FORMAT = 'yyyy-MM-dd';
export const PRETTY_DATE_FORMAT = 'MM-dd-yyyy';
export const PRETTY_DATETIME_FORMAT = 'MM/dd/yy hh:mm a';

export const STATE_SELECT_OPTIONS = () => {
  const statesAbb = [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'ME',
    'MD',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY'
  ];
  return statesAbb.map(s => ({ label: s, value: s }));
};

export const COLORS = {
  SUCCESS: '#7ED321',
  WARNING: '#E6E600',
  FAILED: '#FF151F',
  DEFAULT: '#000000',
  // APPLICATION STATUS
  STATUS_REVIEW: '#FF8D72',
  STATUS_APPROVED: '#2dce89',
  STATUS_DECLINE: '#DC3444',
  STATUS_SMS_SENT: '#11cdef',
  STATUS_SMS_VALID: '#ffd500',
  STATUS_IDENTITY: '#dd4ee8',
  STATUS_DEFAULT: '#212529', // ARCHIVED & INITIATED
  // SUBMERCHANT
  STATUS_ACTIVE: '#2dce89'
};

export const smApplicationsStatus = {
  PRETTY_INITIATED: 'Initiated',
  PRETTY_SMS_SENT: 'SMS Sent',
  PRETTY_SMS_VALID: 'SMS Valid',
  PRETTY_TYPE_SELECTED: 'Type Selected',
  PRETTY_IDENTITY: 'Identity',
  PRETTY_REVIEW: 'Review',
  PRETTY_APPROVED: 'Approved',
  PRETTY_DECLINED: 'Declined',
  PRETTY_ARCHIVED: 'Archived',
  INITIATED: 'initiated',
  SMS_VALID: 'sms_valid',
  SMS_SENT: 'sms_sent',
  TYPE_SELECTED: 'type_selected',
  IDENTITY: 'identity',
  REVIEW: 'review',
  APPROVED: 'approved',
  ACTIVE: 'active',
  DECLINED: 'declined',
  ARCHIVED: 'archived',
  IN_PROGRESS: 'progress'
};

export const USER_ROLES = {
  ADMIN: 'admin',
  UNDERWRITER: 'underwriter',
  MANAGER: 'merchant-manager',
  APPLICANT: 'merchant-applicant',
  MERCHANT_ADMIN: 'merchant-admin',
  EMPLOYEE: 'merchant-employee',
  ACCOUNTING: 'merchant-accounting',
  PROVIDER: 'service-provider',
  CRONJOB: 'cronjob',
  BANK: 'bank'
};

export const SM_LOAN_STATUS = {
  CREATED: 'created',
  LEAD: 'lead',
  REVIEW: 'review',
  OFFER_PUBLISHED: 'offerPublished',
  ACCEPTED: 'accepted',
  ACTIVE: 'active',
  PAID_OFF: 'paidOff'
};

export const IDENTITY_TYPE = {
  INDIVIDUAL: 'individual',
  BUSINESS: 'business',
  GOVERNMENT: 'government-public-corp'
};

export const SUBMERCHANT_STATUS = {
  INITIATED: 'initiated',
  BUSINESS_INFO: 'business_info',
  BANK_SELECTED: 'bank_selected',
  PAYOUT: 'payout',
  REVIEW: 'review',
  PROCESSOR_UNDERWRITING: 'processor_underwriting',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  DECLINED: 'declined',
  // PRETTY STATUSES
  PRETTY_INITIATED: 'Initiated',
  PRETTY_BUSINESS_INFO: 'Business Info',
  PRETTY_BANK_SELECTED: 'Bank Selected',
  PRETTY_PAYOUT: 'Payout',
  PRETTY_REVIEW: 'Review',
  PRETTY_PROCESSOR_UNDERWRITING: 'Processor underwriting',
  PRETTY_ACTIVE: 'Active',
  PRETTY_ARCHIVED: 'Archived',
  PRETTY_DECLINED: 'Declined'
};

export const SUBMERCHANT_PAYOUT_MODE = {
  SCHEDULED: 'scheduled',
  DYNAMIC: 'dynamic',
  MANUAL: 'manual',
  RELEASE_FUNDS: 'release'
};

export const PAYOUT_BENEFICIARIES = {
  PLATFORM: 'platform',
  PRODUCT: 'spProduct',
  SUBMERCHANT: 'submerchant'
};

export const OWNERSHIP_TYPES = {
  PUBLIC_CORP: 'PublicCorporation',
  GOV: 'Government',
  PRIVATE_CORP: 'PrivateCorporation',
  SOLE_PROP: 'SoleProprietorship',
  LLC: 'LLC',
  PARTNERSHIP: 'Partnership',
  NON_PROFIT: 'NonProfit',
  TRUST: 'Trust',
  LIMITED_PARTNERSHIP: 'LimitedPartnership',
  LIMITED_LIABILITY_PARTNERSHIP: 'LimitedLiabilityPartnership'
};

export const BANK_ACCOUNT_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  REVIEW: 'review'
};

export const TABLE_ACTIONS = {
  REMOVE: {
    label: 'Remove',
    key: 'remove',
    icon: 'fal fa-trash-alt'
  },
  EDIT: {
    label: 'Edit',
    key: 'edit',
    icon: 'fal fa-pencil-alt'
  },
  ENABLE: {
    label: 'Enable',
    key: 'enable',
    icon: 'fal fa-toggle-on'
  },
  DISABLE: {
    label: 'Disable',
    key: 'disable',
    icon: 'fal fa-toggle-off'
  },
  PAY: {
    label: 'Pay',
    key: 'pay',
    icon: 'fal fa-credit-card'
  },
  SEND: {
    label: 'Send',
    key: 'send',
    icon: 'fal fa-paper-plane'
  },
  CANCEL: {
    label: 'Cancel',
    key: 'cancel',
    icon: 'fal fa-ban'
  },
  DEFAULT: {
    label: 'Default',
    key: 'default',
    icon: 'fas fa-check-circle'
  }
};

export const INSTRUCTION_TYPE = {
  DEBIT: 'debit',
  CREDIT: 'credit'
};

export const JUPITER_APPS = {
  ACH: 'ach',
  PAYMENTS: 'payments',
  CAPITAL: 'capital',
  ADMIN: 'admin'
};

export const COMMONS_URLS = {
  LOGIN_PAYMENTS: `${config.COMMONS_BACKOFFICE_URL}/${JUPITER_APPS.PAYMENTS}/login`
};

export const ENVIRONMENTS = {
  LOCAL: 'local',
  DEV: 'development',
  SANDBOX: 'sandbox',
  PROD: 'production',
  STAGE: 'stage'
};

export const NOTIFICATION_EVENTS = {
  BANK_ACCOUNT_REMOVED: 'Bank account removed',
  PRODUCT_UPDATED: 'Product updated',
  SAVE_AND_EXIT: 'Saved and exited',
  APPLICATION_INITIATED: 'Application initiated',
  FORGOT_PASSWORD: 'Forgot password',
  PAYOUT_ACK: 'Payout ack',
  PAYOUT_UPDATED: 'Payout updated',
  PAYOUT_CREATED: 'Payout created',
  MOBILE_VERIFIED: 'Mobile verified',
  NEW_SUBMERCHANT_TO_REVIEW: 'New submerchant to review',
  NEW_SUBMERCHANT_CREATED: 'New submerchant created',
  WELCOME_SUCCESS: 'welcome success',
  MERCHANT_ACTIVATED: 'Merchant Activated',
  CHECKS_EXECUTED: 'Checks Executed',
  VERIFICATION_CODE: 'Verification code',
  MOBILE_NUMBER_SET: 'Mobile number set',
  USER_CREATED: 'User created',
  SUBMERCHANT_CREATED: 'SubMerchant created',
  APPLICATION_SUBMITTED: 'Application Submitted',
  APPLICATION_REINITIATED: 'Application reinitiated',
  NEW_PAYOUT_BANK_ACCOUNT: 'Connect bank account',
  ADD_PAYOUT_BANK_ACCOUNT: 'Add payout bank account',
  ACCOUNT_CLONED: 'Account Cloned',
  WEBHOOK: 'Webhook',
  ACK_NOTIFICATION: 'Ack notification',
  NOTIFICATION: 'Notification',
  SEND_SMS: 'SMS',
  SEND_EMAIL: 'Email',
  SEND_EMAIL_TO_ROLE: 'Email role'
};

export const CHECKS_EVENTS = {
  ONBOARDING: 'onboarding',
  UNDERWRITER: 'underwriter',
  MERCHANT_REQUEST: 'submerchant request',
  SERVICE_PROVIDER_REQUEST: 'service provider request'
};

export const CHECKS_SOURCES = {
  MICROBILT: 'microbilt',
  MIDDESK: 'middesk',
  TWILIO: 'twilio'
};
export const CHECKS_MODELS = {
  APPLICANT: 'application',
  SUBMERCHANT: 'subMerchant'
};

export const CHECKS_STATUS = {
  PENDING: 'pending',
  SCORED: 'scored',
  ACTIVE: 'activated',
  VERIFIED: 'verified',
  APPROVED: 'approved',
  RUNNING: 'running'
};

export const CHECKS_TYPES = {
  OWNER_IDENTITY: 'identity',
  OWNER_CREDIT: 'credit',
  MERCHANT_BUSINESS: 'business',
  SMS: 'sms',
  PAYOUT_BANK_ACCOUNTS: 'payout-bank-accounts',
  BANK_IDENTITY: 'bank-identity',
  BANK_BALANCE: 'bank-balance',
  MERCHANT_MONITORING: 'merchant-monitoring'
};
export const PAYMENT_METHOD = {
  CREDIT_CARD: 'creditCard',
  DEBIT: 'debit',
  E_CHECK: 'eCheck',
  GIFT_CARD: 'giftCard',
  UNKNOWN: 'unknown'
};

export const HOSTED_SESSION_STATUS = {
  OPEN: 'open',
  CLOSED: 'closed'
};

export const PROCESSORS = {
  WORLDPAY: 'worldpay',
  SIMULATOR: 'simulator',
  FISERV: 'fiserv',
  CYBERSOURCE: 'cybersource',
  ADYEN: 'adyen',
  STRIPE: 'stripe'
};

export const PROCESSOR_STATUS = {
  REQUESTED: 'requested',
  UNDERWRITING: 'underwriting',
  PROVISIONED: 'provisioned',
  ARCHIVED: 'archived'
};

export const TIN_TYPE_OPTIONS = [
  { value: '1', label: 'SSN' },
  { value: '2', label: 'EIN' },
  { value: '3', label: 'Other' }
];

export const IRS_STATUS_OPTIONS = [
  { label: 'N', value: 'N' },
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'F', value: 'F' },
  { label: 'D', value: 'D' },
  { label: 'C', value: 'C' },
  { label: 'G', value: 'G' },
  { label: 'J', value: 'J' }
];

export const FEE_TYPES = {
  TRANSACTION: 'transaction',
  SCHEDULE: 'schedule',
  OCCURENCE: 'occurence',
  CHARGEBACK: 'chargeback',
  DECLINED: 'declined'
};

export const TRANSACTION_SOURCE = {
  API: 'api',
  CHECKOUT: 'checkout',
  PAYLINK: 'paymentlink',
  BACKOFFICE: 'backoffice',
  INVOICE: 'invoice',
  SUBSCRIPTION: 'subscription'
};

export const CREDIT_CARDS_BRANDS = {
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  DISCOVER: 'discover',
  AMEX: 'amex',
  DINERS: 'diners-club',
  JCB: 'jcb',
  CHINA_UNION_PAY: 'cup',
  MAESTRO: 'maestro',
  DANKORT: 'dankort',
  HIPERCARD: 'hipercard'
};
export const SHARE_METHODS = {
  SMS: 'sms',
  EMAIL: 'email'
};

export const APPS_SUBDOMAINS = {
  ACCOUNTS: 'accounts',
  PAYMENTS: JUPITER_APPS.PAYMENTS,
  PAYMENTS_ONBOARDING: `${JUPITER_APPS.PAYMENTS}-onboarding`,
  PAYMENTS_HOSTED_PAGES: `${JUPITER_APPS.PAYMENTS}-hosted-pages`
};

export const SYNC_TYPES = {
  PAYOUT_BANK_ACCOUNTS: 'payoutBankAccounts',
  OWNERS: 'owners',
  BUSINESS: 'business',
  CONTACTS: 'contacts',
  CONTACT_INFO: 'contactInfo',
  SERVICE_PROVIDER: 'serviceProvider'
};

export const SYNC_STATUS = {
  COMPLETED: 'completed',
  INITIATED: 'initiated',
  FAILED: 'failed'
};

export const PLANS_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

export const SUBSCRIPTIONS_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

export const API_USER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

export const INVOICE_STATUS = {
  DRAFT: 'Draft',
  CREATED: 'Created',
  // SENT: 'Sent',
  PAID: 'Paid',
  OVERDUE: 'Overdue',
  VOIDED: 'Voided'
};

export const PRICING_MODEL_TYPE = {
  FREE: 'free',
  FLAT: 'flat',
  FLAT_TIERED: 'flatTiered',
  PER_MODULE: 'perModule',
  PER_USAGE_QUANTITY: 'perUsageQuantity',
  PER_USAGE_VOLUME: 'perUsageVolume',
  PER_USAGE_TIERED: 'perUsageTiered'
};

export const PRETTY_PRICING_MODEL = {
  free: 'Free',
  flat: 'Flat',
  flatTiered: 'Flat tiered',
  perModule: 'Per module',
  perUsageQuantity: 'Per usage quantity',
  perUsageVolume: 'Per usage volume',
  perUsageTiered: 'Per usage tiered'
};
export const PLAN_EXECUTION_TYPES = {
  AT_SUBSCRIPTION_START: 'atSubscriptionStart',
  AT_PERIOD_END: 'atPeriodEnd',
  EXECUTION_DAYS: 'executionDays'
};

export const PERIOD = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_7_DAYS: 'last-7-days',
  THIS_MONTH: 'this-month',
  THIS_YEAR: 'this-year',
  LAST_6_MONTHS: 'last-6-months',
  ONE_TIME: 'one-time',
  DAILY: 'daily',
  WEEKLY: 'weekly', // 8
  MONTHLY: 'monthly', // 32
  YEARLY: 'yearly', // 32
  QUARTERLY: 'quarterly',
  PER_SEMESTER: 'per-semester',
  ANNUALLY: 'annually'
};

export const ONBOARDING_INVITE_STATUS = {
  CREATED: 'created',
  SENT: 'sent',
  RESENT: 'resent',
  INITIATED: 'initiated',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
  ONBOARDING: 'onboarding'
};

export const HOSTED_SESSION_TYPE = {
  PAYMENT_LINK: 'paymentlink',
  CHECKOUT: 'checkout',
  INVOICE: 'invoice',
  SUBSCRIPTION: 'subscription'
};

export const HOSTED_SESSION_USAGE = {
  SINGLE: 'single_use',
  MULTI: 'multi_use'
};

export const FEATURES = {
  CUSTOMERS: 'customers',
  CC_TXS: 'ccTxs',
  E_CHECK_TXS: 'eCheckTxs',
  CC_TOKENIZATION: 'ccTokenization',
  PAYOUTS: 'payouts',
  PAY_LINKS: 'payLinks',
  SUBSCRIPTIONS: 'subscriptions',
  INVOICES: 'invoices',
  CHECKOUTS: 'checkouts',
  BANK_ACCOUNT_TOKENIZATION: 'bankAccountTokenization',
  VIRTUAL_TERMINAL: 'virtualTerminal'
};

export const FIELD_TYPES = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  TEXT: 'text',
  DATE: 'date',
  INTEGER: 'integer',
  CURRENCY: 'currency',
  PERCENTAGE: 'percentage'
};

export const BILL_TO_OPTIONS_ENUM = {
  MERCHANT: 'submerchant',
  SERVICE_PROVIDER: 'spProduct'
};

export const BILL_TO_OPTIONS = [
  { label: 'Merchant', value: BILL_TO_OPTIONS_ENUM.MERCHANT },
  { label: 'Service Provider', value: BILL_TO_OPTIONS_ENUM.SERVICE_PROVIDER }
];

export const TOKENIZATION_SESSION_PROVIDERS = {
  VGS: 'vgs',
  SIMULATOR: 'simulator',
  MICROFORM: 'microform',
};