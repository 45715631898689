import axios from 'axios';
import cookies from 'js-cookie';
import Vue from 'vue';
import {
  COMMONS_URLS,
  ENVIRONMENTS,
  USER_ROLES,
  JUPITER_APPS
} from '@commons/data/constants';
import config from '@/config';
import {
  getBrowseInfo,
  getBrowseFingerprint,
  improvedStructuredClone
} from '@commons/helpers/utils';

// Mutation types
const SSO = 'security/SSO';
const SIGN_OUT = 'security/SIGN_OUT';
const SET_PROFILE = 'user/SET_PROFILE';
const SET_USER = 'user/SET_USER';
const USER_TOKEN_NAME = 'userToken';
const USER_INFO_NAME = 'userInfo';
const SET_IP = 'user/SET_IP';
const SET_JUPICO_APP = 'user/SET_JUPICO_APP';

// Errors
const CHANGE_PASSWORD_ERROR_MESSAGE = 'Sorry, change password failed.';
const CURRENT_PASSWORD_NOT_VALID_ERROR_MESSAGE = 'The current password is not valid.';
const USERNAME_ALREADY_EXISTS_ERROR_MESSAGE = 'The username already exists.';
const EMAIL_ALREADY_EXISTS_ERROR_MESSAGE = 'E-mail already used.';
const UPDATE_USER_ERROR = 'Sorry, edit profile failed';

const ENDPOINTS = {
  SSO: 'auth/sso',
  SIGN_OUT: 'auth/logout',
  GET_USER: 'users/{id}',
  CHANGE_PASSWORD: 'users/{id}/change-password',
  LOGIN_AS: 'auth/login-as'
};

function initialState() {
  return {
    token: localStorage.getItem(USER_TOKEN_NAME) || null,
    user: JSON.parse(localStorage.getItem(USER_INFO_NAME)) || {},
    profile: {},
    isLoggedInAs: false,
    ip: null,
    jupicoApp: null
  };
}

// initial state
const state = initialState();

function processLoginResponse(response) {
  let token = response.token;
  let user = {
    id: response.userId,
    username: response.username,
    accounts: response.applications.payments.accounts,
    name: response.name,
    acl: response.applications.payments.acl,
    theme: { ...response.applications.payments.theme },
    notifications: response.applications.payments.notifications,
    forcePasswordReset: response.forcePasswordReset,
    productIds: response.applications.payments.productIds,
    sessionId: response.sessionId,
    processors: response.applications.payments.processors
  };
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  localStorage.setItem(USER_TOKEN_NAME, token);
  localStorage.setItem(USER_INFO_NAME, JSON.stringify(user));
  return { user, token };
}

// actions
const actions = {
  async SSO({ commit, dispatch, rootGetters }, sessionId) {
    let browserFingerprint;
    return new Promise(async (resolve, reject) => {
      if (sessionId) {
        // Added this to allow Dev login bypass
        if (
          config.ENV !== ENVIRONMENTS.LOCAL ||
          /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
            sessionId
          )
        )
          browserFingerprint = await getBrowseFingerprint();
        else browserFingerprint = { fingerprint: sessionId };
        axios
          .post(ENDPOINTS.SSO, {
            sessionId,
            browserFingerprint: browserFingerprint.fingerprint,
            facId: rootGetters.getFacilitator.facId,
            application: JUPITER_APPS.PAYMENTS
          })
          .then(response => {
            const processedLoginResponse = processLoginResponse(response.data);
            const roles = processedLoginResponse.user?.acl.reduce(
              (acc, a) => acc + a.role,
              ''
            );
            if (roles?.includes('service-provider')) {
              dispatch('serviceProvider/GET_ACCOUNT_NAMES');
            } else if (roles?.includes('merchant')) {
              dispatch('merchant/GET_ACCOUNT_NAMES');
            } else {
              dispatch('GET_ACCOUNT_NAMES');
            }

            commit(SSO, processedLoginResponse);
            commit(SET_IP, browserFingerprint?.ip);
            resolve(processedLoginResponse.user);
          })
          .catch(e => {
            if (e.response.data.message === 'Not Authorized: wrong api user') {
              Vue.prototype.$notify({
                message: 'User is not in the database, run populateDB',
                timeout: 7000,
                icon: 'fa fa-circle-xmark',
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'danger'
              });
            }
            reject(e.response ? e.response.data.message : undefined);
          });
      } else {
        dispatch('CLEAR_AUTH_DATA');
        if (config.ENV !== ENVIRONMENTS.LOCAL || !config.BYPASS_COMMONS_LOGIN) {
          window.location.replace(COMMONS_URLS.LOGIN_PAYMENTS);
        } else resolve();
      }
    });
  },
  SIGN_OUT({ state, dispatch }) {
    return new Promise(resolve => {
      if (config.ENV === ENVIRONMENTS.LOCAL && config.BYPASS_COMMONS_LOGIN) {
        dispatch('CLEAR_AUTH_DATA');
        resolve(true);
      } else
        axios.post(ENDPOINTS.SIGN_OUT, { token: state.token }).finally(() => {
          dispatch('CLEAR_AUTH_DATA');
          resolve(true);
        });
    });
  },
  CLEAR_AUTH_DATA({ commit, rootGetters }) {
    localStorage.removeItem(USER_TOKEN_NAME);
    localStorage.removeItem(USER_INFO_NAME);
    localStorage.removeItem('accountNames');
    cookies.remove('loggedInAsSessionId', { domain: config.BASE_DOMAIN });
    if (rootGetters.getJupicoApp === JUPITER_APPS.PAYMENTS) {
      cookies.remove('sessionId', { domain: config.BASE_DOMAIN });
    } else cookies.remove('aSessionId', { domain: config.BASE_DOMAIN });
    commit(SIGN_OUT);
  },
  GET_LOGGED_USER({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_USER.replace('{id}', id))
        .then(response => {
          commit(SET_PROFILE, response.data);
          resolve();
        })
        .catch(e => reject('Error trying to get the user'));
    });
  },
  CLEAR_PROFILE({ commit }) {
    commit(SET_PROFILE, {});
  },
  UPDATE_USER({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(ENDPOINTS.GET_USER.replace('{id}', data._id), data)
        .then(() => {
          commit(SET_PROFILE, data);
          resolve();
        })
        .catch(error => {
          if (error.response?.data?.message) reject(error.response.data.message);

          if (error.response) {
            const { data = {} } = error.response;
            return data.stack.includes('email')
              ? reject(EMAIL_ALREADY_EXISTS_ERROR_MESSAGE)
              : data.stack.includes('username')
              ? reject(USERNAME_ALREADY_EXISTS_ERROR_MESSAGE)
              : reject(UPDATE_USER_ERROR);
          }
        });
    });
  },
  CHANGE_PASSWORD(context, data) {
    return new Promise((resolve, reject) => {
      let user = Object.assign({}, data);
      delete user.id;
      axios
        .post(ENDPOINTS.CHANGE_PASSWORD.replace('{id}', data.id), user)
        .then(() => {
          resolve();
        })
        .catch(error => {
          if (error.response?.status === 401)
            reject(CURRENT_PASSWORD_NOT_VALID_ERROR_MESSAGE);
          reject(error.response?.data?.message || CHANGE_PASSWORD_ERROR_MESSAGE);
        });
    });
  },
  ACTION_SET_THEME_SETTINGS({ commit }, data) {
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO_NAME));
    localStorage.setItem(
      'userInfo',
      JSON.stringify({ ...userInfo, theme: { ...userInfo.theme, ...data } })
    );
    commit('SET_THEME_SETTINGS', data);
  },
  async LOGIN_AS(context, userId) {
    try {
      const browserInfo = await getBrowseInfo();
      const response = await axios.post(ENDPOINTS.LOGIN_AS, {
        userId,
        application: JUPITER_APPS.PAYMENTS,
        browserInfo
      });
      cookies.set('loggedInAsSessionId', response.data.sessionId, {
        domain: config.BASE_DOMAIN
      });
      if (
        response.data.applications.payments.acl.some(
          a =>
            a.role === USER_ROLES.ADMIN ||
            a.role === USER_ROLES.UNDERWRITER ||
            a.role === USER_ROLES.BANK
        )
      )
        window.location.href = window.location.origin;
      else window.open(config.PAYMENT_BACKOFFICE_URL, '_blank');
    } catch (error) {
      throw 'Error while logging in';
    }
  },
  async LOGIN_AS_SSO({ commit, dispatch }, loggedInAsSessionId) {
    const user = await dispatch('SSO', loggedInAsSessionId);
    commit('SET_IS_LOGGED_IN_AS', true);
    return user;
  },
  EXIT_FROM_LOGGED_IN_AS({ commit, rootGetters }) {
    cookies.remove('loggedInAsSessionId', { domain: config.BASE_DOMAIN });
    localStorage.removeItem(USER_TOKEN_NAME);
    localStorage.removeItem(USER_INFO_NAME);
    localStorage.removeItem('accountNames');
    commit('SET_IS_LOGGED_IN_AS', false);
    const roles = rootGetters.loggedInUser.acl;
    commit(SIGN_OUT);
    if (
      roles.some(
        a =>
          a.role === USER_ROLES.ADMIN ||
          a.role === USER_ROLES.UNDERWRITER ||
          a.role === USER_ROLES.BANK
      )
    )
      window.location.href = window.location.origin;
  },
  REMOVE_NOTIFICATION_FROM_LOGGED_IN_USER({ state, commit }, notificationId) {
    let user = improvedStructuredClone(state.user);
    const notificationIdx = user.notifications.findIndex(n => n.id === notificationId);
    if (notificationIdx > -1) {
      user.notifications.splice(notificationIdx, 1);
      localStorage.setItem(USER_INFO_NAME, JSON.stringify(user));
      commit(SET_USER, user);
    }
  },
  UPDATE_LOGGED_IN_USER_OBJECT({ commit }, newUserInfo) {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const user = {
      ...userInfo,
      ...newUserInfo
    };
    localStorage.setItem(USER_INFO_NAME, JSON.stringify(user));
    commit(SET_USER, user);
  }
};

// getters
const getters = {
  isAuthenticated: state => !!state.token,
  loggedInUser: state => state.user,
  getProfileInfo: state => state.profile,
  getAccounts: state => state.user.accounts,
  getThemeSettings: state => state.user.theme,
  getIsLoggedInAs: state => state.isLoggedInAs,
  getIp: state => state.ip,
  isLoggedUserAdmin: state => state.user?.acl?.some(a => a.role === USER_ROLES.ADMIN),
  getJupicoApp: state => state.jupicoApp
};

// mutations
const mutations = {
  [SSO](state, data) {
    const { token, user } = data;
    state.token = token;
    state.user = user;
  },
  [SIGN_OUT](state) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      if (key !== 'jupicoApp') state[key] = s[key];
    });
    axios.defaults.headers.common['Authorization'] = null;
  },
  [SET_USER](state, data) {
    state.user = data;
  },
  [SET_PROFILE](state, data) {
    state.profile = data;
  },
  SET_THEME_SETTINGS(state, data) {
    state.user = {
      ...state.user,
      theme: { ...state.user.theme, ...data }
    };
  },
  SET_IS_LOGGED_IN_AS(state, data) {
    state.isLoggedInAs = data;
  },
  [SET_IP](state, data) {
    state.ip = data;
  },
  [SET_JUPICO_APP](state, data) {
    state.jupicoApp = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
