import { formatDateSelectors } from '@commons/helpers/date';
import axios from 'axios';
import FileDownload from 'js-file-download';
import qs from 'qs';

// Mutation types
const GET_SUBMERCHANTS = 'SUBMERCHANTS/GET_SUBMERCHANTS';
const GET_ACCOUNTS_TABLE = 'SUBMERCHANTS/GET_ACCOUNTS_TABLE';
const SET_SUBMERCHANT_APPLICATION = 'SUBMERCHANTS/SET_SUBMERCHANT_APPLICATION';
const SET_SUBMERCHANT = 'SUBMERCHANTS/SET_SUBMERCHANT';
const SET_MY_SUBMERCHANTS = 'SUBMERCHANTS/SET_MY_SUBMERCHANTS';
const SET_ACCOUNT_NAMES = 'SUBMERCHANTS/SET_ACCOUNT_NAMES';

const BASE_ACCOUNTS_ENDPOINT = 'backoffice/accounts/view';
const BASE_SUBMERCHANT_ENDPOINT = `${BASE_ACCOUNTS_ENDPOINT}/{id}/submerchants/{subMerchantObjId}`;
const ENDPOINTS = {
  ACCOUNTS: BASE_ACCOUNTS_ENDPOINT,
  CLONE: `${BASE_ACCOUNTS_ENDPOINT}/clone`,
  SUBMERCHANTS: `${BASE_ACCOUNTS_ENDPOINT}/submerchants`,
  SINGLE_ACCOUNT: `${BASE_ACCOUNTS_ENDPOINT}/{id}`,
  NEW_ACCOUNT: `${BASE_ACCOUNTS_ENDPOINT}/new`,
  SUBMERCHANT: BASE_SUBMERCHANT_ENDPOINT,
  GET_RESERVED_FUNDS: `${BASE_ACCOUNTS_ENDPOINT}/{id}/reserved`,
  BANK_ACCOUNT_ENDPOINT: `${BASE_ACCOUNTS_ENDPOINT}/submerchants/payoutbankaccount`
};

function initialState() {
  return {
    list: {},
    accountList: {},
    subMerchantApplication: { logs: [] },
    mySubMerchants: {},
    accountNames: JSON.parse(localStorage.getItem('accountNames')) || [],
    subMerchant: {}
  };
}

// initial state
const state = initialState();

// actions
const actions = {
  GET_SUBMERCHANTS({ commit }, data) {
    const params = formatDateSelectors(data);
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.SUBMERCHANTS, {
          params,
          paramsSerializer: params =>
            qs.stringify(params, { indices: false, allowDots: true })
        })
        .then(response => {
          commit(GET_SUBMERCHANTS, response.data);
          resolve(response.data);
        })
        .catch(() => reject('Error loading submerchants'));
    });
  },
  async GET_ACCOUNTS_TABLE({ commit }, data) {
    const params = formatDateSelectors(data);
    if (params.owners) {
      params['identity.owners.firstName'] = params.owners;
      params['identity.owners.lastName'] = params.owners;
      delete params.owners;
    }
    if (params.business) {
      params['subMerchantDocs.identity.business.dbaName'] = params.business;
      params['subMerchantDocs.identity.business.legalName'] = params.business;
      delete params.business;
    }
    try {
      const response = await axios.get(ENDPOINTS.ACCOUNTS, {
        params,
        paramsSerializer: params =>
          qs.stringify(params, { indices: false, allowDots: false }),
        cancelPreviousRequests: true
      });
      commit(GET_ACCOUNTS_TABLE, response.data);
      return response.data;
    } catch (error) {
      throw new Error('Error loading applications');
    }
  },
  async DOWNLOAD_ACCOUNTS_TABLE(context, data) {
    const params = formatDateSelectors(data);
    if (params.owners) {
      params['identity.owners.firstName'] = params.owners;
      params['identity.owners.lastName'] = params.owners;
      delete params.owners;
    }
    if (params.mainOwner) {
      params.identity = {
        owners: { firstName: params.mainOwner, lastName: params.mainOwner }
      };
      delete params.mainOwner;
    }
    if (params.business) {
      params['subMerchantDocs.identity.business.dbaName'] = params.business;
      params['subMerchantDocs.identity.business.legalName'] = params.business;
      delete params.business;
    }
    const response = await axios.get(ENDPOINTS.ACCOUNTS, {
      params: { ...params, exportFormat: 'csv' },
      paramsSerializer: params =>
        qs.stringify(params, { indices: false, allowDots: false }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/csv'
      },
      cancelPreviousRequests: true
    });
    FileDownload(response.data, 'accounts.csv');
  },
  CLEAR_ACCOUNTS({ commit }) {
    commit(GET_SUBMERCHANTS, initialState().list);
    commit(GET_ACCOUNTS_TABLE, initialState().list);
  },
  GET_MY_SUBMERCHANTS({ commit }, data) {
    const params = formatDateSelectors(data);
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.SUBMERCHANTS, {
          params,
          paramsSerializer: params =>
            qs.stringify(params, { indices: false, allowDots: true })
        })
        .then(response => {
          commit(SET_MY_SUBMERCHANTS, response.data);
          resolve(response.data);
        })
        .catch(() => reject('Error loading applications'));
    });
  },
  CLEAR_MY_SUBMERCHANTS({ commit }) {
    commit(SET_MY_SUBMERCHANTS, initialState().mySubMerchants);
  },
  GET_SUBMERCHANT_APPLICATION({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.SINGLE_ACCOUNT.replace('{id}', id))
        .then(response => {
          commit(SET_SUBMERCHANT_APPLICATION, response.data);
          resolve(response.data);
        })
        .catch(() => reject('Error in GET_SUBMERCHANT_APPLICATION'));
    });
  },
  CLEAR_SUBMERCHANT_APPLICATION({ commit }) {
    commit(SET_SUBMERCHANT_APPLICATION, { logs: [] });
  },
  GET_ACCOUNT_NAMES({ commit }) {
    const params = {
      fields:
        'identity.business.dbaName,identity.business.location,subMerchantId,tokenization.publicKey,processor,settings,features'
    };
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.SUBMERCHANTS, { params })
        .then(response => {
          commit(SET_ACCOUNT_NAMES, response.data.data, { root: true });
          localStorage.setItem('accountNames', JSON.stringify(response.data.data));
          resolve();
        })
        .catch(() => reject());
    });
  },
  GET_SUBMERCHANT({ commit }, { smApplicationId, subMerchantObjId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          ENDPOINTS.SUBMERCHANT.replace('{id}', smApplicationId).replace(
            '{subMerchantObjId}',
            subMerchantObjId
          )
        )
        .then(response => {
          commit(SET_SUBMERCHANT, response.data);
          resolve(response.data);
        })
        .catch(() => reject('Error in GET_SUBMERCHANT'));
    });
  },
  CLEAR_SUBMERCHANT({ commit }) {
    commit(SET_SUBMERCHANT, {});
  },
  async GET_RESERVED_FUNDS({ commit }, data) {
    try {
      const response = await axios.get(
        ENDPOINTS.GET_RESERVED_FUNDS.replace('{id}', data.subMerchantId)
      );
      if (!response.data.success) throw 'Could not fetch reserved funds';
      return response.data.data.amount;
    } catch (error) {
      throw error;
    }
  },
  async UPDATE_SUBMERCHANT({ commit }, data) {
    try {
      const response = await axios.put(
        ENDPOINTS.SUBMERCHANT.replace('{id}', data.id).replace(
          '{subMerchantObjId}',
          data.subMerchantObjId
        ),
        data
      );
      commit(SET_SUBMERCHANT, response.data);
      return response.data;
    } catch (error) {
      const { response } = error;
      const message = response?.data ? response.data.message || 'An error occurred while updating the submerchant.' : null;
      throw new Error(message);
    }
  },
  async CREATE_NEW_ACCOUNT({ commit }, data) {
    try {
      const response = await axios.post(ENDPOINTS.NEW_ACCOUNT, data);
      if (!response.data.success) {
        throw new Error(response.data.message || 'Could not create new account');
      }
      return response.data;
    } catch (error) {
      const { response } = error;
      const message = response?.data
        ? response.data.message || 'An error occurred while creating the account.'
        : null;
      throw new Error(message);
    }
  },
  async ADD_PAYOUT_BANK_ACCOUNT({ commit }, data) {
    try {
      const response = await axios.post(ENDPOINTS.BANK_ACCOUNT_ENDPOINT, { ...data });
      commit(SET_SUBMERCHANT, response.data);
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        'Error while adding the bank account. Try again later';
      throw new Error(errorMessage);
    }
  },
  async SET_PREFERRED_BANK_ACCOUNT({ commit }, data) {
    try {
      const response = await axios.put(ENDPOINTS.BANK_ACCOUNT_ENDPOINT, { ...data });
      commit(SET_SUBMERCHANT, response.data);
      return true;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        'Error while setting the preferred bank account. Try again later';
      throw new Error(errorMessage);
    }
  },
  async DELETE_PAYOUT_BANK_ACCOUNT({ commit }, data) {
    try {
      const response = await axios.delete(ENDPOINTS.BANK_ACCOUNT_ENDPOINT, {
        data: { ...data }
      });
      commit(SET_SUBMERCHANT, response.data);
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        'Error while deleting the bank account. Try again later';
      throw new Error(errorMessage);
    }
  }
};

// getters
const getters = {
  getApplications: state => state.list,
  getTableAccounts: state => state.accountList,
  getSubMerchantApplication: state => state.subMerchantApplication,
  mySubMerchants: state => state.mySubMerchants,
  accountNames: state => state.accountNames,
  subMerchant: state => state.subMerchant
};

// mutations
const mutations = {
  [GET_SUBMERCHANTS](state, data) {
    state.list = data;
  },
  [GET_ACCOUNTS_TABLE](state, data) {
    state.accountList = data;
  },
  [SET_SUBMERCHANT_APPLICATION](state, data) {
    state.subMerchantApplication = data;
  },
  [SET_SUBMERCHANT](state, data) {
    state.subMerchant = data;
  },
  [SET_MY_SUBMERCHANTS](state, data) {
    state.mySubMerchants = data;
  },
  [SET_ACCOUNT_NAMES](state, data) {
    state.accountNames = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
