import { formatDateSelectors } from '@commons/helpers/date';
import axios from 'axios';
import qs from 'qs';

// Endpoints
const BASE_INVITES_ENDPOINT = 'backoffice/invites';
const SINGLE_INVITE_ENDPOINT = `${BASE_INVITES_ENDPOINT}/:inviteId`;

const SEND_INVITE_ENDPOINT = `${SINGLE_INVITE_ENDPOINT}/send`;
const DELETE_INVITE_ENDPOINT = `${SINGLE_INVITE_ENDPOINT}/delete`;

const CREATE_AND_SEND_INVITE_ENDPOINT = `${BASE_INVITES_ENDPOINT}/createAndSend`;

const SET_INVITES = 'onboardingInvites/SET_INVITES';

// initial state
const state = { invites: {} };

// actions
const actions = {
  ADD_INVITE(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_INVITES_ENDPOINT, data)
        .then(response => resolve(response.data))
        .catch(e => {
          if (e.response?.data?.message) reject(e.response.data.message);
          else reject('Error creating Invite');
        });
    });
  },
  EDIT_INVITE(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(SINGLE_INVITE_ENDPOINT.replace(':inviteId', data._id), data)
        .then(response => resolve(response.data))
        .catch(e => {
          if (e.response?.data?.message) reject(e.response.data.message);
          else reject('Error while editing the Invite. Try again later.');
        });
    });
  },
  DELETE_INVITE(context, { inviteId, spProductId }) {
    return new Promise((resolve, reject) => {
      axios
        .post(DELETE_INVITE_ENDPOINT.replace(':inviteId', inviteId), {}, { params: { spProductId } })
        .then(response => resolve(response.data))
        .catch(e => {
          if (e.response?.data?.message) reject(e.response.data.message);
          else reject('Error while deleting the Invite. Try again later.');
        });
    });
  },
  SEND_INVITE(context, { inviteId, spProductId }) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          SEND_INVITE_ENDPOINT.replace(':inviteId', inviteId),
          {},
          { params: { spProductId } }
        )
        .then(response => resolve(response.data))
        .catch(e => {
          if (e.response?.data?.message) reject(e.response.data.message);
          else reject('Error while sending the Invite. Try again later.');
        });
    });
  },
  GET_INVITES({ commit }, data) {
    const params = formatDateSelectors(data);
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_INVITES_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { indices: false })
        })
        .then(response => {
          commit(SET_INVITES, {
            count: response.data.count,
            data: response.data.data
          });
          resolve(response.data);
        })
        .catch(e => {
          if (e.response?.data?.message) reject(e.response.data.message);
          else reject('Error while getting the Invites. Try again later.');
        });
    });
  },
  ADD_INVITE_TO_LIST({ commit, state }, invite) {
    let invites = JSON.parse(JSON.stringify(state.invites));
    const elementIndex = invites.data.findIndex(c => c._id === invite._id);
    const inviteToAdd = { ...invite };
    if (elementIndex === -1) {
      invites.data.unshift(inviteToAdd);
      invites.count++;
    } else {
      invites.data[elementIndex] = inviteToAdd;
    }
    commit(SET_INVITES, invites);
  },
  DELETE_INVITE_FROM_LIST({ commit, state }, inviteId) {
    let invites = JSON.parse(JSON.stringify(state.invites));
    const elementIndex = invites.data.findIndex(c => c._id === inviteId);
    if (elementIndex !== -1) {
      invites.data.splice(elementIndex, 1);
      invites.count--;
    }
    commit(SET_INVITES, invites);
  },
  CLEAR_INVITES({ commit }) {
    commit(SET_INVITES, { count: 0, data: [] });
  },
  UPLOAD_CSV_INVITES({ commit }, file) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);

      axios
        .post(`${BASE_INVITES_ENDPOINT}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => resolve(response.data))
        .catch(e => {
          const detailedError = e.response?.data ? JSON.parse(e.response.data.message) : null;
          if (detailedError?.data?.failed) {
            reject(detailedError.data.failed.results.map(result => result.message).join(", "));
          } else {
            reject(new Error('Error while uploading the Invites. Try again later.'));
          }
        });
    });
  },
  CREATE_AND_SEND_INVITE({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(CREATE_AND_SEND_INVITE_ENDPOINT, data)
        .then(response => {
          commit('ADD_INVITE_TO_LIST', response.data);
          resolve(response.data);
        })
        .catch(e => {
          if (e.response?.data?.message) reject(e.response.data.message);
          else reject('Error creating and sending Invite');
        });
    });
  }
};

const getters = {
  getInvites: state => state.invites
};

const mutations = {
  [SET_INVITES](state, data) {
    state.invites = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
