<template>
  <section
    v-if="isIE"
    class="main-body-wrapper"
    style="
      display: inline-block;
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: #535a72;
      font-weight: 300;
      margin: 100px auto 40px;
    "
  >
    <div class="container p-0">
      <card
        class="mx-auto"
        style="padding: 48px 40px; max-width: 830px"
        bodyClasses="p-0"
      >
        <h1 class="mb-2">Unsupported Browser</h1>
        <p>
          {{ getFacilitator.name }} is committed to providing modern, secure technologies
          to our customers and as such we no longer support the Internet Explorer 11
          browser per Microsoft's recommendation. Please access this application from any
          of the following browsers:
          <a href="https://www.microsoft.com/edge" target="_blank">Microsoft Edge</a>,
          <a href="https://www.google.com/chrome" target="_blank">Chrome</a>,
          <a href="https://www.mozilla.com" target="_blank">Firefox</a>, or
          <a href="https://www.apple.com/safari" target="_blank">Safari</a>
        </p>
      </card>
    </div>
  </section>
  <router-view v-else></router-view>
</template>

<script>
import { COMMONS_URLS, ENVIRONMENTS } from '@commons/data/constants';
import { Helpers } from '@commons/mixins/Helpers';
import axiosInterceptors from '@commons/mixins/axiosInterceptors';
import { setUiThemeColors } from '@commons/uiTheme';
import bowser from 'bowser';
import { mapGetters } from 'vuex';
// ensure that the element-ui css is loaded
import 'element-ui/lib/theme-chalk/index.css';

const webkitScrollbarCSS = `
        ::-webkit-scrollbar {
          width: 13px;
        }
        ::-webkit-scrollbar-track {
          background: #27293D;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #5D5F7F;
          border-radius: 1px;
        }
        // ::-webkit-scrollbar-button {
        //   background: #27293D;
        // }
        ::-webkit-scrollbar-corner {
          background: #27293D;
        }
      `;
// import cookies from 'js-cookie';

export default {
  mixins: [Helpers, axiosInterceptors],
  computed: {
    ...mapGetters(['getThemeSettings', 'isAuthenticated', 'getFacilitator']),
    isIE() {
      try {
        return (
          bowser.getParser(window.navigator.userAgent).getBrowserName() ===
          'Internet Explorer'
        );
      } catch (error) {
        return false;
      }
    }
  },
  mounted() {
    this.updateUI();
    this.createWcScriptTag();
  },
  onIdle() {
    if (this.isAuthenticated) this.dispatchSignOutExpired();
  },
  methods: {
    updateUI() {
      const themeSettings = this.getThemeSettings;
      if (!themeSettings) {
        return;
      }

      const { sidebarColor, mode, sidebarMini } = themeSettings;

      if (sidebarColor) {
        setUiThemeColors(sidebarColor, mode === 'dark');
      }

      if (mode === 'dark') {
        document.body.classList.remove('white-content');
        document.documentElement.setAttribute('color-scheme', 'dark');

        // Customizing scrollbars for dark mode
        document.documentElement.style.scrollbarWidth = '11px';
        document.documentElement.style.scrollbarColor = '#5D5F7F #27293D';
        document.documentElement.style.overflow = '-moz-scrollbars-none'; // Hide scrollbar on Firefox

        const scrollbarStyleElement = document.getElementById('scrollbar-style');
        if (scrollbarStyleElement !== null) {
          scrollbarStyleElement.remove();
        }

        const webkitScrollbarStyle = document.createElement('style');
        webkitScrollbarStyle.setAttribute('id', 'scrollbar-style');

        webkitScrollbarStyle.textContent = webkitScrollbarCSS;
        document.head.appendChild(webkitScrollbarStyle);
      } else {
        // Resetting scrollbar styles to default for light mode
        document.documentElement.style.scrollbarWidth = '';
        document.documentElement.style.scrollbarColor = '';
        document.documentElement.style.overflow = '';
        const webkitScrollbarStyle = document.querySelector('#scrollbar-style');
        if (webkitScrollbarStyle) {
          webkitScrollbarStyle.remove();
        }

        document.body.classList.add('white-content');
        document.documentElement.removeAttribute('color-scheme');
      }

      if (sidebarMini !== this.$sidebar.isMinimized) {
        this.$sidebar.toggleMinimize();
      }
    },
    dispatchSignOutExpired() {
      this.$notifyInfo(
        'Your session has expired (after 60 mins of inactivity).\nPlease log in again to continue.'
      );
      this.$store.dispatch('CLEAR_AUTH_DATA');
      if (this.$config.ENV !== ENVIRONMENTS.LOCAL || !this.$config.BYPASS_COMMONS_LOGIN)
        window.location.replace(COMMONS_URLS.LOGIN_PAYMENTS);
      else this.$router.push({ name: 'DevLogin' });
    },
    createWcScriptTag() {
      const wcScript = document.createElement('script');
      wcScript.src = `${this.$config.HOSTED_PAGES_URL}/wc/tokenization-form.umd.js`;
      wcScript.id = 'web-components';
      document.head.appendChild(wcScript);
    }
  },
  watch: {
    getThemeSettings: function () {
      this.updateUI();
    }
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');
@import '../node_modules/vue-loading-overlay/dist/vue-loading.css';
@import './assets/sass/styles/styles.scss';

.swal2-container {
  z-index: 10060 !important;
}
</style>
