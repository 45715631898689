<template>
  <DashboardLayout :app="JUPITER_APPS.PAYMENTS">
    <template slot="links">
      <sidebar-item
        v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.dashboard')"
        :link="{
          name: 'Dashboard',
          icon: 'tim-icons icon-chart-bar-32',
          path: '/dashboard'
        }"
      />
      <sidebar-item
        v-if="
          loggedInUser.acl.some(a => a.role === 'service-provider') &&
          UI_ABILITIES.check(loggedInUser.acl, 'sidebar.service-provider.accounts')
        "
        :link="{
          name: 'Accounts',
          icon: 'fal fa-address-card',
          path: '/accounts-center'
        }"
      />
      <sidebar-item
        v-else-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.merchant.accounts')"
        :link="{
          name: 'Accounts',
          icon: 'fal fa-address-card',
          path: '/accounts-view'
        }"
      />
      <sidebar-item
        v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.invites')"
        :link="{
          name: 'Invites',
          icon: 'fal fa-envelope',
          path: '/invites'
        }"
      />
      <sidebar-item
        v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.disputes')"
        :link="{
          name: 'Disputes',
          icon: 'fal fa-bell-exclamation',
          path: '/disputes'
        }"
      />
      <sidebar-item
        v-if="
          UI_ABILITIES.check(loggedInUser.acl, 'sidebar.customers', FEATURES.CUSTOMERS)
        "
        :link="{
          name: 'Customers',
          icon: 'fal fa-user-tag',
          path: '/customers'
        }"
      />
      <sidebar-item
        v-if="
          UI_ABILITIES.check(loggedInUser.acl, 'sidebar.virtualTerminal', FEATURES.VIRTUAL_TERMINAL)
        "
        :link="{
          name: 'Virtual Terminal',
          icon: 'fal fa-cash-register',
          path: '/virtual-terminal'
        }"
      />
      <sidebar-item
        v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.invoices', FEATURES.INVOICES)"
        :link="{ name: 'Invoices', icon: 'fal fa-file-invoice', path: '/invoices' }"
      />
      <sidebar-item
        v-if="
          ![ENVIRONMENTS.PROD, ENVIRONMENTS.SANDBOX].includes($config.ENV) &&
          UI_ABILITIES.check(loggedInUser.acl, 'sidebar.subscriptions', FEATURES.SUBSCRIPTIONS)
        "
        :link="{
          name: 'Subscriptions',
          icon: 'fal fa-timer',
          path: '/subscriptions/plans'
        }"
      >
      </sidebar-item>
      <sidebar-item
        v-if="
          UI_ABILITIES.check(loggedInUser.acl, 'sidebar.hostedSessions') ||
          UI_ABILITIES.check(loggedInUser.acl, 'hosted.paylink') ||
          UI_ABILITIES.check(loggedInUser.acl, 'sidebar.transactions') ||
          UI_ABILITIES.check(loggedInUser.acl, 'sidebar.declined')
        "
        :link="{ name: 'Payments', icon: 'fal fa-shopping-cart' }"
      >
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.transactions')"
          :link="{ name: 'Transactions', path: '/reporting/transactions' }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.hostedSessions')"
          :link="{ name: 'Hosted Sessions', path: '/reporting/hosted-sessions' }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'hosted.paylink', FEATURES.PAY_LINKS)"
          :link="{ name: 'Send Payment Link', path: '/send/payment-link' }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.declined')"
          :link="{ name: 'Declined', path: '/reporting/transactions/declines' }"
        />
      </sidebar-item>
      <sidebar-item
        v-if="
          UI_ABILITIES.check(loggedInUser.acl, 'sidebar.settlements') ||
          UI_ABILITIES.check(loggedInUser.acl, 'sidebar.fees') ||
          UI_ABILITIES.check(loggedInUser.acl, 'sidebar.payouts')
        "
        :link="{ name: 'Reporting', icon: 'fal fa-tasks' }"
      >
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.fees')"
          :link="{ name: 'Fees', path: '/reporting/fees' }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.payouts', FEATURES.PAYOUTS)"
          :link="{ name: 'Payouts', path: '/reporting/payouts' }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.settlements')"
          :link="{ name: 'Settlement', path: '/reporting/settlement' }"
        />
      </sidebar-item>
      <!-- <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.ledger')"
          :link="{
            name: 'Ledger',
            icon: 'fal fa-money-check-dollar-pen',
            path: '/reporting/ledger'
          }"
        /> -->
      <!-- <sidebar-item
        v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.system')"
        :link="{
          name: 'System',
          icon: 'fal fa-monitor-waveform',
          path: '/system'
        }"
      /> -->
      <sidebar-item
        v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.users')"
        :link="{ name: 'Users', icon: 'fal fa-user-cog' }"
      >
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.usersManage')"
          :link="{ name: 'Manage', path: '/users/manage' }"
        />
      </sidebar-item>
      <sidebar-item
        v-if="UI_ABILITIES.check(loggedInUser.acl, 'sidebar.pci-compliance')"
        :link="{
          name: 'PCI Compliance',
          path: '/pci-compliance',
          icon: 'fal fa-shield-check'
        }"
      />
    </template>
  </DashboardLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardLayout from '@commons/pages/Layout/DashboardLayout';
import { JUPITER_APPS, ENVIRONMENTS, FEATURES } from '@commons/data/constants';
import { UI_ABILITIES } from '@commons/data/role-ui-abilities';

export default {
  components: { DashboardLayout },
  props: { app: { type: String, default: null } },
  data() {
    return { JUPITER_APPS, ENVIRONMENTS, UI_ABILITIES, FEATURES };
  },
  computed: { ...mapGetters(['loggedInUser', 'accountNames']) }
};
</script>
