import axios from 'axios';
import swal from 'sweetalert2';

export default {
  data() {
    return {
      currentVersion: undefined,
      isSwalOpen: false,
      pendingRequests: {}
    };
  },
  created() {
    axios.interceptors.request.use(config => {
      // we only want to cancel requests if config.cancelPreviousRequests is true
      // if the config.url exists
      // if the config doesn't contain AbortController.signal already, in this case we let the developer handle it himself
      if (config?.cancelPreviousRequests && !config.signal) {
        // remove request URL from pending requests and also abort ongoing call with same URL
        this.removePendingRequest(config.url, true);
        const abortController = new AbortController(); //create new AbortController
        config.signal = abortController.signal; // assign it's signal into request config
        this.pendingRequests[config.url] = abortController; // store AbortController in the pending requests map
      }
      return config;
    });
    axios.interceptors.response.use(
      response => {
        if (!this.currentVersion) this.currentVersion = response.headers['x-app-version'];
        if (
          response.headers['x-app-version'] &&
          response.headers['x-app-version'] !== this.currentVersion &&
          this.isSwalOpen === false
        ) {
          this.isSwalOpen = true;
          swal.fire({
            title: 'Application Upgraded',
            text: 'A new version has been released, please log back in.',
            icon: 'info',
            confirmButtonText: 'OK',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            reverseButtons: true,
            focusConfirm: true,
            focusCancel: false,
            showCloseButton: false,
            showLoaderOnConfirm: true,
            preConfirm: () =>
              this.$store.dispatch('SIGN_OUT').then(() => window.location.reload())
          });
        }
        // remove pending request without aborting the call, call is finished
        this.removePendingRequest(response.request.responseURL);
        return response;
      },
      error => {
        if (
          error.response?.status === 401 &&
          error.response?.data?.message?.includes('expired') &&
          this.isAuthenticated
        )
          this.dispatchSignOutExpired();
        this.removePendingRequest(error.config?.url);
        return Promise.reject(error);
      }
    );
  },
  methods: {
    removePendingRequest(url, abort = false) {
      // check if pendingRequests contains our request URL
      if (url && this.pendingRequests[url]) {
        // if we want to abort ongoing call, abort it
        if (abort) {
          this.pendingRequests[url].abort();
        }
        // remove the request URL from pending requests
        delete this.pendingRequests[url];
      }
    }
  }
};
